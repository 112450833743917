import React, { useState } from "react";
import "./App.css";
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import ThirdPartyEmailPassword, {
    ThirdPartyEmailPasswordAuth,
    signOut,
    Google,
    Github,
    Apple,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import Home from "./Home";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "./Footer";
import SessionExpiredPopup from "./SessionExpiredPopup";

const isDev = window.location.href.includes('://localhost')
const isStaging = window.location.href.includes('.sling.vinedatalabs.com')
const isProd = window.location.href.includes('.slingdata.io')
const isSignOut = window.location.href.includes('signOut')
const cookieDomain = isProd ? ".slingdata.io"  : isStaging ? '.sling.vinedatalabs.com' : undefined



const appURL = isDev ? 'http://localhost:3001' :
                isStaging ? 'https://app.sling.vinedatalabs.com' :
                isProd ? 'https://app.slingdata.io' :
                window.location.origin

export function getApiDomain() {
    if (isDev) return `http://localhost:4000`;
    if (isStaging) return `https://api.sling.vinedatalabs.com`;
    if (isProd) return `https://api.slingdata.io`;

    const apiPort = process.env.REACT_APP_API_PORT || 3003;
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    return window.location.origin;
}

export async function doSignOut() {
    delete_cookie('sAccessToken', cookieDomain)
    delete_cookie('sFrontToken', cookieDomain)
    delete_cookie('sIRTFrontend', cookieDomain)
    delete_cookie('sIdRefreshToken', cookieDomain)
    await signOut()
    window.location.href = window.location.origin
}

SuperTokens.init({
    enableDebugLogs: isDev || isStaging,
    appInfo: {
        appName: "Sling",
        apiDomain: getApiDomain(), 
        websiteDomain: getWebsiteDomain(),
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    recipeList: [
        ThirdPartyEmailPassword.init({
            preAPIHook: async (context) => {
              console.log(window.location)
              console.log(context)

              let url = context.url;

              // is the fetch config object that contains the header, body etc..
              let requestInit = context.requestInit;

              let action = context.action;
              if (action === "EMAIL_EXISTS") {

              } else if (action === "GET_AUTHORISATION_URL") {

              } else if (action === "IS_EMAIL_VERIFIED") {
                window.location.href = appURL
              } else if (action === "SEND_RESET_PASSWORD_EMAIL") {

              } else if (action === "SEND_VERIFY_EMAIL") {

              } else if (action === "EMAIL_PASSWORD_SIGN_IN") {

              } else if (action === "EMAIL_PASSWORD_SIGN_UP") {
                if(isStaging) {
                  // delete user princelrc85@yahoo.com
                  await stagingDeleteUser()
                }
              } else if (action === "THIRD_PARTY_SIGN_IN_UP") {
                  // Note: this could either be sign in or sign up.
                  // we don't know that at the time of the API call
                  // since all we have is the authorisation code from
                  // the social provider

              } else if (action === "SUBMIT_NEW_PASSWORD") {
              } else if (action === "VERIFY_EMAIL") {
                setTimeout(() => {
                  window.location.href = window.location.origin  // to load app
                  window.location.reload()
                }, 100);
              }

              // events such as sign out are in the 
              // session recipe pre API hook (See the info box below)

              return {
                  requestInit, url
              };
            },
            getRedirectionURL: async (context) => {
                console.log(window.location)
                console.log(context)
                if (isSignOut) {
                  await doSignOut()
                  return
                }

                if (context.action === "SUCCESS") {
                    if (context.redirectToPath !== undefined) {
                        // we are navigating back to where the user was before they authenticated
                      // window.location.href = context.redirectToPath;
                      return context.redirectToPath;
                    }

                    window.location.href = appURL
                    return appURL;
                }
                return undefined;
            },
            signInAndUpFeature: {
                providers: [
                  Github.init(),
                  Google.init(),
                  Apple.init(),
                ],
                signUpForm: {
                  termsOfServiceLink: "https://slingdata.io/terms-of-service",
                  privacyPolicyLink: "https://slingdata.io/privacy-policy",
                  formFields: [{
                      id: "name",
                      label: "Full Name",
                      placeholder: "First & Last Name",
                  }]
                }
            },
            emailVerificationFeature: {
                mode: "REQUIRED",
            },
        }),
        Session.init({
          cookieDomain: cookieDomain
        }),
    ],
});

function App() {
    let [showSessionExpiredPopup, updateShowSessionExpiredPopup] = useState(false);

    React.useEffect(() => {
      if (isSignOut) doSignOut()
      else if (isDev || isStaging) getUser()
    }, [])

    return (
        <div className="App">
            <Router>
                <div className="fill">
                    <Routes>
                        {/* This shows the login UI on "/auth" route */}
                        {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"))}

                        <Route
                            path="/"
                            element={
                                /* This protects the "/" route so that it shows 
                                   <Home /> only if the user is logged in.
                                   Else it redirects the user to "/auth" */
                                <ThirdPartyEmailPasswordAuth
                                    onSessionExpired={() => {
                                        updateShowSessionExpiredPopup(true);
                                    }}>
                                    <Home />
                                    {showSessionExpiredPopup && <SessionExpiredPopup />}
                                </ThirdPartyEmailPasswordAuth>
                            }
                        />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </div>
    );
}


export const apiGet = async (route, extraHeaders = {}) => {
  let url = `${getApiDomain()}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, { headers, credentials: 'include' })
    let data = await response.json()
    if (response.status >= 400 && !data.error) data.error = response.statusText
    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    }
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    }
  }
}

export const apiDelete = async (route, payload={}, extraHeaders={}) => {
  let url = `${getApiDomain()}${route}`
  let headers = Object.assign({ 'Access-Control-Allow-Origin': '*', "Content-Type": "application/json" }, extraHeaders)

  try {
    let response = await fetch(url, {
      method: 'delete',
      headers: headers,
      body: JSON.stringify(payload),
    })
    let data = await response.json()

    if (response.status >= 400 && !data.error) data.error = response.statusText

    return {
      status: response.status,
      statusText: response.statusText,
      data: data,
      error: data.error,
    }
  } catch (error) {
    return {
      status: 555,
      statusText: `${error}`,
      data: {},
      error: `${error}`,
    }
  }
}

export const stagingDeleteUser = async () => {
  console.log('stagingDeleteUser')
  const headers = {'Sling-API-Key': 'No9ouVfMDUg2lqEvaGZmDrMX5qGibu7j'}
  const url = `${getApiDomain()}/api/v1/users`
  await apiDelete(url, {"email":"princelrc85@yahoo.com"}, headers)
}

export const getUser = async () => {
  await apiGet('/api/v1/user')
}

export function delete_cookie(name, domain) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  if(domain) document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain}`
}

export default App;
